<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">实验终点</h3>
    <div class="detail">
      <div class="flex  backReturn">
        <h4 class="title">大体解剖观察记录</h4>
        <div>
          <el-button size="small" plain class="return">导出</el-button>
          <el-button size="small" plain class="return" v-if="!checkState&&state>=1"
                     @click="$emit('changePageGroup', true,3)">返回
          </el-button>
        </div>
      </div>
      <table class="table specTable">
        <tr>
          <th colspan="3">方案编号</th>
          <td colspan="3">{{ activeInfo.number }}</td>
          <th colspan="3">动物品系</th>
          <td colspan="3">{{ activeInfo.number }}</td>
        </tr>
        <tr>
          <th colspan="3">死亡类型</th>
          <td colspan="10">①急性失血 ②空气栓塞 ③颈椎脱臼 ④其他</td>
        </tr>
        <tr>
          <th>组别</th>
          <th>动物编号</th>
          <th>耳号</th>
          <th>性别</th>
          <th>处理方式</th>
          <th>死亡类型</th>
          <th>大体解剖观察</th>
          <th>备注</th>
          <th>操作人</th>
          <th>操作日期</th>
          <template v-if="status == 58">
            <th>复核人</th>
            <th>复核日期</th>
          </template>
        </tr>
        <tr v-for="item in endDetailList" :key="item.groupAniId">
          <td>{{ item.groupName }}</td>
          <td>{{ item.aniNo }}</td>
          <td>{{ item.overbit }}</td>
          <td>{{ item.gender == 1 ? '♂' : '♀' }}</td>
          <td>{{ item.handle == 0 ? '回收箱丢弃' : '解剖处理' }}</td>
          <td>
            <template v-if="item.deathType==0">①</template>
            <template v-if="item.deathType==2">②</template>
            <template v-if="item.deathType==3">③</template>
            <template v-if="item.deathType==4">④</template>
          </td>
          <td>{{ item.dissection }}</td>
          <td>{{ item.remark }}</td>
          <td>{{ item.createName }}</td>
          <td>{{ item.createDate | formatDay }}</td>
          <template v-if="status == 58">
            <td>{{ item.reviewName }}</td>
            <td>{{ item.reviewDate | formatDay }}</td>
          </template>
        </tr>
      </table>
      <template v-if="state==1&&checkState">
        <h4 class="title">复核结果</h4>
        <el-form :model="recordForm" ref="recordForm" size="small" class="addForm" label-suffix=":"
                 label-width="90px">
          <el-form-item label="复核结果">
            <el-radio-group v-model="recordForm.state" class="radioGroup">
              <el-radio :label="2">复核通过</el-radio>
              <el-radio :label="3">返回修改(复核意见必填)</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="复核意见" prop="reviewRemark"
                        :rules="validateType">
            <el-input type="textarea" rows="4" placeholder="请输入复核意见" v-model="recordForm.reviewRemark"></el-input>
          </el-form-item>
          <div class="fromSave">
            <el-button type="primary" size="small" @click="checkSubmit('recordForm')">复核完成</el-button>
          </div>
        </el-form>
      </template>
      <template v-else-if="state==0||state==1&&!checkState"></template>
      <template v-else-if="state==3">
        <h4 class="title">复核结果</h4>
        <div class="desc">
          <p>复核结果：{{ end.state == 2 ? '复核通过' : end.state == 3 ? '复核拒绝' : '' }}</p>
          <p>复核意见：{{ end.reviewRemark }}</p>
          <span>复核人：{{ end.reviewName }}</span>
          <span>复核日期：{{ end.reviewTime | formatDay }}</span>
        </div>
      </template>
    </div>
  </div>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "recordEnd",
  props: ['id', 'status'],
  data() {
    return {
      activeInfo: {},
      medicine: {
        partName: "dddd",
        createTime: "2018-05-01 23:00:00"
      },
      endDetailList: [],
      state: null,
      end: {},
      recordForm: {
        state: 2,
        reviewRemark: "",
      },
      checkState: false,
    }
  },
  computed: {
    ...mapState({
      user: state => state.account.user
    }),
    validateType() {
      return this.recordForm.state === 3
          ? [{required: true, message: '请输入复核意见', trigger: 'blur'}]
          : []
    },
  },
  mounted() {
    this.$get("/subject/" + this.id).then((res) => {
      if (res.status == 200) {
        this.activeInfo = res.data
      }
    })
    if (this.status == 58) {
      this.getCheckData()
    } else {
      this.getData()
    }
  },
  methods: {
    getData() {
      this.$get('/subject/end/' + this.id).then(res => {
        if (res.status == 200) {
          this.state = res.data.end.state
          this.end = res.data.end
          if (this.user.userId == res.data.end.reviewer) {
            this.checkState = true
          }
          this.endDetailList = res.data.endDetailList
        }
      })
    },
    // 提交复核
    checkSubmit(checkForm) {
      this.$refs[checkForm].validate((valid) => {
        if (valid) {
          this.recordForm.subId = this.id
          this.$put('/subject/end/state', this.recordForm).then(res => {
            if (res.status == 200) {
              this.$message.success('提交成功')
              this.$router.go(-1)
            }
          })
        } else {
          return false;
        }
      })
    },
    // 复核人员复核同意之后，查看该课题所有动物实验终点记录
    getCheckData() {
      this.$get('/subject/end/detail/' + this.id).then(res => {
        if (res.status == 200) {
          this.endDetailList = res.data
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";

</style>
